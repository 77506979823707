<template>
  <div class="home" style="height:100%">
    <el-container style="height: 100%">
      <el-header>
        <header-layout></header-layout>
      </el-header>
      <el-main style="width: 100%; margin: auto;">
        <div style="display: flex; width: 90%;">
          <div style="flex: 1; text-align: left; margin-left: 11%; margin-top: 0.5rem">
            <h1 class="title" @click="refresh">指标浏览器</h1>
            <el-tooltip content="点击查看使用说明" effect="dark" placement="top">
              <i class="el-icon-question" style="color: #409EFF; margin-left: 2px;"
                 @click="() => this.$refs.drawer1.visible = true"/>
            </el-tooltip>
          </div>
          <el-date-picker
            v-model="monthChoose"
            :clearable="false"
            placeholder="选择日期（月份）"
            type="month"
            value-format="yyyy-MM"
            @blur="chooseMonth">
          </el-date-picker>
        </div>
        <p style="width: 80%; text-align: left; margin: 0 auto">
          点击具体ESG指标列可以在按照此列递增分页排序，按照此列递减分页排序和清空排序规则之间切换，也可以点击上下箭头直接选定按照某一列递增或递减分页排序。
        </p>

        <el-drawer
          ref="drawer2"
          :before-close="handleDrawerClose"
          :with-header="false"
          direction="rtl"
          size="40%"
          title="我是标题"
        >
          <iframe
            ref="drawer1iframe"
            onload="this.style.height= (window.innerHeight - 90) +'px';"
            src="https://doc.textualesg.com/document/pointerDesc.html"
            style="width: 100%; border: hidden"
            title="ESG指标"
          >
          </iframe>
        </el-drawer>
        <div style="display: flex; width: 80%; margin-left: 10%; height: 4rem; align-items: center;">
          <div class="other-btns" style="flex: 1;">
            <el-button plain size="medium" style="margin-bottom: 1rem; margin-top: 1rem;" type="info"
                       @click="() => this.$refs.drawer2.visible = true"><i class="el-icon-info"></i>&nbsp;指标说明
            </el-button>
            <el-button v-if="companyMode === true" :disabled="topBtn" size="medium" type="primary" @click="resetTop"><i
              class="el-icon-s-flag"></i>&nbsp;置顶&nbsp;/&nbsp;取消
            </el-button>
            <el-button id="all-btn" :loading="allBtn" size="medium" type="success" @click="exportAllData"><i
              class="el-icon-download"></i>&nbsp;导出全部
            </el-button>
            <el-button v-show="checkedCompanyCode.length > 0" id="check-btn" :loading="multiBtn" size="medium"
                       type="info" @click="exportMultiSelect"><i class="el-icon-finished"></i>&nbsp;导出所选
            </el-button>
            <el-button v-show="checkedCompanyCode.length > 0" id="clear-btn" size="medium" type="danger"
                       @click="clearAllChecked"><i class="el-icon-delete"></i>&nbsp;清空已选
            </el-button>
            <el-button id="check-comb-btn" :loading="checkBtn" size="medium" style="display: none; margin: 0"
                       type="success" @click="exportCheckedCombData"><i class="el-icon-download"></i>&nbsp;导出已选组合指标
            </el-button>
            <el-button id="comb-btn" :loading="combBtn" size="medium" style="display: none;" type="info"
                       @click="exportCombData"><i class="el-icon-download"></i>&nbsp;导出全部组合指标
            </el-button>
          </div>
          <div style="display: inline-flex; align-items: center;">
            <!--            <el-button plain size="medium" type="info" style="margin: 1rem;" @click="() => this.$refs.drawer2.visible = true"><i class="el-icon-info"></i>&nbsp;指标说明</el-button>-->
            <div>
              <el-select
                v-model="selectCombination"
                clearable collapse-tags filterable multiple
                placeholder="请选择组合"
                style="height: 2.5rem;"
                @change="changeCombinationSelect">
                <el-checkbox v-model="isCombinationAllSelected" class="selectAll-checkbox"
                             @change='selectAllCombination'>全选
                </el-checkbox>
                <el-option
                  v-for="item in combinationOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="parseInt(item.value) - 1">
                </el-option>
                <el-button size="mini" style="float: right; margin: 2%;" type="text" @click="ensureFilter">确认查询
                </el-button>
              </el-select>
            </div>
            <el-button size="medium" style="margin: 1rem;" type="info" @click="goToCombination"><i
              class="el-icon-setting"></i>&nbsp;管理组合
            </el-button>
            <div>
              <el-input
                v-model="keyword"
                placeholder="请输入完整公司名或代码"
                prefix-icon="el-icon-search"
                style="height: 2.5rem;"
                @keyup.enter.native="ensureSearch">
              </el-input>
            </div>
          </div>
        </div>
        <div>
          <el-table
            id="table"
            ref="multipleTable"
            v-loading.fullscreen.lock="loading"
            :data="tableData"
            :row-class-name="isTop"
            :row-key="getRowKey"
            :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
            element-loading-text="数据加载中"
            highlight-current-row
            style="width: 80%; left: 10%;"
            @select="handleSelectChange"
            @current-change="handleSingleSelect"
            @cell-click="handleCompanyClick"
            @header-contextmenu="handleHeaderClick"
            @sort-change="handleSortChange"
            @filter-change="filterIndustry"
            @select-all="handleSelectAll">
            <el-table-column
              v-if="companyMode"
              :key="1"
              :selectable="selectable"
              align="center"
              type="selection"
              width="45">
            </el-table-column>
            <el-table-column
              align="center"
              label="股票代码"
              prop="code"
              width="120"></el-table-column>
            <el-table-column
              v-if="companyMode"
              align="center"
              label="公司名称"
              prop="name"
              width="110">
              <template v-slot="scope">
                <el-tooltip content="点击查看公司详情" effect="dark" placement="right">
                  <el-link type="primary">{{ scope.row.name }}<i class="el-icon-view el-icon--right"></i></el-link>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              v-if="!companyMode"
              align="center"
              label="组合名称"
              prop="name"
              width="110">
            </el-table-column>
            <el-table-column
              :filters="industryOptions"
              align="center"
              label="所属行业"
              prop="industry"
              width="110">
              <template v-slot="scope">
                <span>
                  <i
                    :class="`el-icon--right ${icon_class[Object.values(industryNames).indexOf(scope.row.industry)]}`"></i>
                  {{ scope.row.industry }}</span>
              </template>
            </el-table-column>
            <el-table-column
              :formatter="formatNum"
              align="center"
              label="ESG总总评"
              min-width="83"
              prop="ave_total"
              sortable='custom'></el-table-column>
            <el-table-column
              :formatter="formatNum"
              align="center"
              label="CSR报告总评"
              min-width="83"
              prop="ave_csr"
              sortable='custom'></el-table-column>
            <el-table-column
              v-if="showColumns[0]"
              :formatter="formatNum"
              align="center"
              label="CSR报告E评分"
              min-width="83"
              prop="csr_e"
              sortable='custom'></el-table-column>
            <el-table-column
              v-if="showColumns[0]"
              :formatter="formatNum"
              align="center"
              label="CSR报告S评分"
              min-width="83"
              prop="csr_s"
              sortable='custom'></el-table-column>
            <el-table-column
              v-if="showColumns[0]"
              :formatter="formatNum"
              align="center"
              label="CSR报告G评分"
              min-width="83"
              prop="csr_g"
              sortable='custom'></el-table-column>
            <el-table-column
              :formatter="formatNum"
              align="center"
              label="调研报告总评"
              min-width="83"
              prop="ave_qa"
              sortable='custom'></el-table-column>
            <el-table-column
              v-if="showColumns[1]"
              :formatter="formatNum"
              align="center"
              label="调研报告E评分"
              min-width="83"
              prop="qa_e"
              sortable='custom'></el-table-column>
            <el-table-column
              v-if="showColumns[1]"
              :formatter="formatNum"
              align="center"
              label="调研报告S评分"
              min-width="83"
              prop="qa_s"
              sortable='custom'></el-table-column>
            <el-table-column
              v-if="showColumns[1]"
              :formatter="formatNum"
              align="center"
              label="调研报告G评分"
              min-width="83"
              prop="qa_g"
              sortable='custom'></el-table-column>
            <el-table-column
              :formatter="formatNum"
              align="center"
              label="分析师报告总评"
              min-width="83"
              prop="ave_ana"
              sortable='custom'></el-table-column>
            <el-table-column
              v-if="showColumns[2]"
              :formatter="formatNum"
              align="center"
              label="分析师报告E评分"
              min-width="83"
              prop="ana_e"
              sortable='custom'></el-table-column>
            <el-table-column
              v-if="showColumns[2]"
              :formatter="formatNum"
              align="center"
              label="分析师报告S评分"
              min-width="83"
              prop="ana_s"
              sortable='custom'></el-table-column>
            <el-table-column
              v-if="showColumns[2]"
              :formatter="formatNum"
              align="center"
              label="分析师报告G评分"
              min-width="83"
              prop="ana_g"
              sortable='custom'></el-table-column>
            <el-table-column
              :formatter="formatNum"
              align="center"
              label="公司新闻总评"
              min-width="83"
              prop="ave_news"
              sortable='custom'></el-table-column>
            <el-table-column
              v-if="showColumns[3]"
              :formatter="formatNum"
              align="center"
              label="公司新闻E评分"
              min-width="83"
              prop="news_e"
              sortable='custom'></el-table-column>
            <el-table-column
              v-if="showColumns[3]"
              :formatter="formatNum"
              align="center"
              label="公司新闻S评分"
              min-width="83"
              prop="news_s"
              sortable='custom'></el-table-column>
            <el-table-column
              v-if="showColumns[3]"
              :formatter="formatNum"
              align="center"
              label="公司新闻G评分"
              min-width="83"
              prop="news_g"
              sortable='custom'></el-table-column>
            <!--            <el-table-column-->
            <!--              prop="stru"-->
            <!--              min-width="83"-->
            <!--              align="center"-->
            <!--              :formatter="formatNum"-->
            <!--              sortable='custom'-->
            <!--              :render-header="headerTooltip"-->
            <!--              label="结构化数据总评"></el-table-column>-->
            <!--            <el-table-column-->
            <!--              prop="stru_e"-->
            <!--              min-width="83"-->
            <!--              align="center"-->
            <!--              :formatter="formatNum"-->
            <!--              sortable='custom'-->
            <!--              v-if="showColumns[4]"-->
            <!--              label="结构化数据E评分"></el-table-column>-->
            <!--            <el-table-column-->
            <!--              prop="stru_s"-->
            <!--              min-width="83"-->
            <!--              align="center"-->
            <!--              :formatter="formatNum"-->
            <!--              sortable='custom'-->
            <!--              v-if="showColumns[4]"-->
            <!--              label="结构化数据S评分"></el-table-column>-->
            <!--            <el-table-column-->
            <!--              prop="stru_g"-->
            <!--              min-width="83"-->
            <!--              align="center"-->
            <!--              :formatter="formatNum"-->
            <!--              sortable='custom'-->
            <!--              v-if="showColumns[4]"-->
            <!--              label="结构化数据G评分"></el-table-column>-->
          </el-table>
        </div>
        <div style="float: right; margin-right: 10%; margin-top: 1rem;">
          <el-pagination
            :current-page.sync="currentPage"
            :page-size.sync="pageSize"
            :page-sizes="[5, 10, 20, 50, 100]"
            :total="totalNum"
            background
            hide-on-single-page
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="getAllData"
            @current-change="getAllData">
          </el-pagination>
        </div>
        <el-drawer
          ref="drawer1"
          :before-close="handleDrawerClose"
          :with-header="false"
          direction="rtl"
          size="40%"
          title="我是标题"
        >
          <iframe
            ref="drawer1iframe"
            onload="this.style.height= (window.innerHeight - 90) +'px';"
            src="https://doc.textualesg.com/guide/userguide.html#esg%E8%AF%84%E7%BA%A7%E7%B1%BB"
            style="width: 100%; border: hidden"
            title="使用说明-ESG评级"
          >
          </iframe>
        </el-drawer>
      </el-main>
    </el-container>
    <FooterLayoutSlim></FooterLayoutSlim>
  </div>
</template>

<script>

import HeaderLayout from '../../components/HeaderLayout.vue'
import { getCombInfo } from '@/api/combination'
import {
  exportCheckedRateData,
  exportRateData,
  getCompaniesRateData, getCurDate,
  getRateData,
  searchRateData
} from '@/api/indexbrowser'
import FooterLayout from '../../components/FooterLayout'
import FooterLayoutSlim from '../../components/FooterLayoutSlim'
// import { latestMonth, latestYear } from '@/utils'

export default {
  name: 'indexbrowser',
  inject: ['reload'],
  components: {
    FooterLayoutSlim,
    FooterLayout,
    HeaderLayout
  },
  data () {
    return {
      latestMonth:'',
      latestYear:'',
      loading: false,
      sortedBy: null,
      sortedOrder: null,
      monthChoose: this.latestYear + '-' + this.latestMonth,
      monthStore: this.latestYear + '-' + this.latestMonth,
      keyword: '',
      currentPage: 1,
      pageSize: 10,
      totalNum: 1000,
      topRowNames: [],
      currentRowData: null,
      multiBtn: false,
      allBtn: false,
      combBtn: false,
      checkBtn: false,
      isAllSelected: false,
      selectIndustry: [],
      industryModify: [],
      isCombinationAllSelected: false,
      selectCombination: [],
      combinationOptions: [],
      combCompanies: [],
      combCompaniesData: {},
      combinationsInfo: [],
      industryOptions: [
        {
          value: 'agriculture',
          text: '农林牧渔'
        }, {
          value: 'chemicals',
          text: '基础化工'
        }, {
          value: 'steel',
          text: '钢铁'
        }, {
          value: 'nonferrous_metal',
          text: '有色金属'
        }, {
          value: 'electronics',
          text: '电子'
        }, {
          value: 'cars',
          text: '汽车'
        }, {
          value: 'household_ele',
          text: '家用电器'
        }, {
          value: 'foods',
          text: '食品饮料'
        }, {
          value: 'clothes',
          text: '纺织服饰'
        }, {
          value: 'light_m',
          text: '轻工制造'
        }, {
          value: 'medicine',
          text: '医药生物'
        }, {
          value: 'utilities',
          text: '公用事业'
        }, {
          value: 'transportation',
          text: '交通运输'
        }, {
          value: 'real_estate',
          text: '房地产'
        }, {
          value: 'commercial_retail',
          text: '商贸零售'
        }, {
          value: 'social_service',
          text: '社会服务'
        }, {
          value: 'banking',
          text: '银行'
        }, {
          value: 'nobank_finance',
          text: '非银金融'
        }, {
          value: 'comprehensive',
          text: '综合'
        }, {
          value: 'building_mat',
          text: '建筑材料'
        }, {
          value: 'building_dec',
          text: '建筑装饰'
        }, {
          value: 'power',
          text: '电力设备'
        }, {
          value: 'mechanical',
          text: '机械设备'
        }, {
          value: 'defense',
          text: '国防军工'
        }, {
          value: 'cs',
          text: '计算机'
        }, {
          value: 'media',
          text: '传媒'
        }, {
          value: 'communication',
          text: '通信'
        }, {
          value: 'coal',
          text: '煤炭'
        }, {
          value: 'petroleum',
          text: '石油石化'
        }, {
          value: 'e_protection',
          text: '环保'
        }, {
          value: 'beauty_care',
          text: '美容护理'
        }],
      industryNames: {
        agriculture: '农林牧渔',
        chemicals: '基础化工',
        steel: '钢铁',
        nonferrous_metal: '有色金属',
        electronics: '电子',
        cars: '汽车',
        household_ele: '家用电器',
        foods: '食品饮料',
        clothes: '纺织服饰',
        light_m: '轻工制造',
        medicine: '医药生物',
        utilities: '公用事业',
        transportation: '交通运输',
        real_estate: '房地产',
        commercial_retail: '商贸零售',
        social_service: '社会服务',
        banking: '银行',
        nobank_finance: '非银金融',
        comprehensive: '综合',
        building_mat: '建筑材料',
        building_dec: '建筑装饰',
        power: '电力设备',
        machanical: '机械设备',
        defense: '国防军工',
        cs: '计算机',
        media: '传媒',
        communication: '通信',
        coal: '煤炭',
        petroleum: '石油石化',
        e_protection: '环保',
        beauty_care: '美容护理'
      },
      scoreList: [
        'ave_csr', 'ave_qa', 'ave_ana', 'ave_news', 'ave_total',
        'csr_e', 'csr_s', 'csr_g', 'qa_e', 'qa_s', 'qa_g', 'ana_e', 'ana_s', 'ana_g',
        'news_e', 'news_s', 'news_g'
      ],
      checkedCompanyCode: [],
      topTableData: [],
      // 对接后端获取全部评级数据
      tableData: [
        {
          industry: '银行, 行业2',
          name: '组合1名称',
          code: '',
          csr_e: '2.6401',
          csr_s: '2.71001',
          csr_g: '-2.6401',
          qa_e: '0.26401',
          qa_s: '264.01',
          qa_g: '2.99995',
          ana_e: '2.64001',
          ana_s: '02.6401',
          ana_g: '-02.6401',
          news_e: '2.0',
          news_s: '2.6401',
          news_g: '2.6401',
          stru_e: '-0.55',
          stru_s: '001',
          stru_g: '1',
          children: [
            {
              industry: '环保',
              name: '羊头公司',
              code: '666666',
              csr_e: '2.6401',
              csr_s: '2.31001',
              csr_g: '-2.3001',
              qa_e: '0.23001',
              qa_s: '230.01',
              qa_g: '2.99995',
              ana_e: '2.30001',
              ana_s: '02.3001',
              ana_g: '-02.3001',
              news_e: '2.0',
              news_s: '2.3001',
              news_g: '2.3001',
              stru_e: '-0.55',
              stru_s: '001',
              stru_g: '0.999'
            },
            {
              industry: '计算机',
              name: '星环科技',
              code: '114514',
              csr_e: '2.6401',
              csr_s: '2.7106',
              csr_g: '-2.646',
              qa_e: '0.2646',
              qa_s: '264.6',
              qa_g: '2.99995',
              ana_e: '2.6406',
              ana_s: '02.646',
              ana_g: '-02.646',
              news_e: '2.0',
              news_s: '2.646',
              news_g: '2.646',
              stru_e: '-0.55',
              stru_s: '06',
              stru_g: '-1'
            }
          ]
        },
        {
          industry: '银行',
          name: '平安银行',
          code: '000001',
          csr_e: '0.6401',
          csr_s: '0.71001',
          csr_g: '0.0901',
          qa_e: '0.20901',
          qa_s: '0.01',
          qa_g: '0.99995',
          ana_e: '0.09001',
          ana_s: '0.0901',
          ana_g: '0.0901',
          news_e: '0.0',
          news_s: '0.0901',
          news_g: '0.0901',
          stru_e: '0.55',
          stru_s: '0.01',
          stru_g: '1'
        }],
      showColumns: [false, false, false, false, false],
      companyMode: true,
      topBtn: true,
      icon_class: ['el-icon-grape', 'el-icon-magic-stick', 'el-icon-attract', 'el-icon-medal', 'el-icon-cpu', 'el-icon-truck',
        'el-icon-refrigerator', 'el-icon-ice-drink', 'el-icon-watch-1', 'el-icon-toilet-paper', 'el-icon-first-aid-kit',
        'el-icon-help', 'el-icon-box', 'el-icon-office-building', 'el-icon-goods', 'el-icon-service', 'el-icon-bank-card',
        'el-icon-coin', 'el-icon-connection', 'el-icon-brush', 'el-icon-scissors', 'el-icon-s-opportunity', 'el-icon-setting',
        'el-icon-aim', 'el-icon-monitor', 'el-icon-mic', 'el-icon-phone-outline', 'el-icon-discover', 'el-icon-odometer',
        'el-icon-sunny', 'el-icon-star-off']
    }
  },
  methods: {
    handleDrawerClose (done) {
      this.$refs.drawer1.visible = false
      this.$refs.drawer2.visible = false
    },
    getRowKey (row) {
      if (row.fatherName) return row.fatherName + row.code
      else if (row.code === 6) return row.code
      else return row.name
    },
    formatNum (row, column, cellValue) {
      let num = cellValue
      num = parseFloat(num)
      num = Math.round(num * Math.pow(10, 4)) / Math.pow(10, 4)
      num = Number(num).toFixed(4)
      return num + ''
    },
    selectable (row, index) {
      return (!row.fatherName) && row.code
    },
    filterIndustry (filters) {
      this.selectIndustry = Object.values(filters)[0]
      this.getAllData()
    },
    isTop (row) {
      if (this.topRowNames.includes(row.row.name)) {
        return 'top-row'
      } else if ((row.rowIndex - this.topRowNames.length) % 2 === 0) {
        return 'even-row'
      } else {
        return 'odd-row'
      }
    },
    handleSingleSelect (row) {
      this.currentRowData = row
      this.topBtn = false
    },
    resetTop () {
      // 置顶/取消置顶
      const idx = this.topTableData.indexOf(this.currentRowData)
      if (idx !== -1) {
        this.topTableData.splice(idx, 1)
        this.topRowNames.splice(idx, 1)
        this.tableData = []
        this.getAllData()
      } else {
        if (this.topTableData.length < this.pageSize) {
          this.topTableData.push(this.currentRowData)
          this.topRowNames.push(this.currentRowData.name)
          // this.$message({
          //   message: '置顶成功',
          //   type: 'success',
          //   center: true
          // })
          this.tableData = []
          this.getAllData()
        } else {
          this.$message({
            message: '置顶满啦',
            type: 'warning',
            center: true
          })
        }
      }
    },
    handleHeaderClick (column, event) {
      const p = ['csr', 'qa', 'ana', 'news', 'stru']
      // console.log(column.property)
      for (let i = 0; i < p.length; i++) {
        if (column.property.includes(p[i])) {
          this.showColumns[i] = !this.showColumns[i]
        }
      }
      // console.log(this.showColumns)
      this.$forceUpdate()
    },
    headerTooltip (h, { column }) {
      const tooltips = '单击以排序，右键以查看ESG单项详情'
      return h(
        'div', {
          style: {
            display: 'inline-block'
          }
        }, [
          h('span', column.label),
          h('el-tooltip', {
            props: {
              placement: 'top'
            }
          }, [
            h('div', {
              slot: 'content',
              style: {
                whiteSpace: 'normal',
                display: 'inline-block'
              }
            }, tooltips),
            h('i', {
              class: 'el-icon-info',
              style: 'color: #409EFF; margin-left: 2px; display: inline-block;'
            })
          ])
        ]
      )
    },
    handleSortChange (tmp) {
      // 按指标或公司代码表格整体排序
      let sortKey = tmp.prop
      this.sortedOrder = String(tmp.order).substring(0, 1)
      if (this.sortedOrder === 'n') this.sortedOrder = null
      if (!this.scoreList.includes(sortKey)) {
        sortKey = 'company_code'
      }
      if (!this.sortedOrder) sortKey = null
      this.sortedBy = sortKey
      console.log(this.sortedBy, this.sortedOrder)
      this.getAllData()
    },
    handleSelectChange (selection, row) {
      const idx = this.checkedCompanyCode.indexOf(row.code)
      if (idx !== -1) this.checkedCompanyCode.splice(idx, 1)
      else if (row.code.length === 6) this.checkedCompanyCode.push(row.code)
      console.log(this.checkedCompanyCode)
    },
    handleSelectAll (selection) {
      if (selection.length === 0) {
        this.tableData.forEach(row => {
          const idx = this.checkedCompanyCode.indexOf(row.code)
          if (idx !== -1) this.checkedCompanyCode.splice(idx, 1)
        })
      } else {
        selection.forEach(row => {
          const idx = this.checkedCompanyCode.indexOf(row.code)
          if (idx === -1) this.checkedCompanyCode.push(row.code)
        })
      }
    },
    clearAllChecked () {
      this.checkedCompanyCode = []
      this.$message({
        message: '清空成功',
        type: 'success',
        center: true
      })
      this.$store.commit('setLastCheckDate', { lastCheckDate: this.monthChoose })
      this.reload()
    },
    // 跨页回显已选项
    preChecked () {
      this.$nextTick(() => {
        for (let i = 0; i < this.tableData.length; i++) {
          if (this.checkedCompanyCode.includes(this.tableData[i].code)) {
            this.$refs.multipleTable.toggleRowSelection(this.tableData[i], true)
          }
        }
      })
    },
    exportMultiSelect () {
      // 导出所有checkbox勾选的行数据
      this.multiBtn = true
      // this.$refs.multipleTable.selection.forEach(row => {
      //   this.checkedCompanyCode.push(row.code)
      // })
      console.log(this.checkedCompanyCode)
      const year = parseInt(this.monthChoose.substring(0, 4))
      const month = parseInt(this.monthChoose.substring(this.monthChoose.length - 2))
      exportCheckedRateData(year, month, this.checkedCompanyCode).then(res => {
        if (res.code !== 0) {
          this.$message({
            message: '导出失败',
            type: 'danger',
            center: true
          })
          this.multiBtn = false
          return
        }
        this.multiBtn = false
        this.$message({
          message: '导出成功',
          type: 'success',
          center: true
        })
        const tmp = document.createElement('a')
        tmp.setAttribute('href', res.data)
        console.log(res.data)
        tmp.click()
      })
    },
    exportCombData () {
      // 导出组合内公司数据
      this.combBtn = true
      const year = parseInt(this.monthChoose.substring(0, 4))
      const month = parseInt(this.monthChoose.substring(this.monthChoose.length - 2))
      exportCheckedRateData(year, month, this.combCompanies).then(res => {
        if (res.code !== 0) {
          this.$message({
            message: '导出失败',
            type: 'danger',
            center: true
          })
          this.combBtn = false
          return
        }
        this.combBtn = false
        this.$message({
          message: '导出成功',
          type: 'success',
          center: true
        })
        const tmp = document.createElement('a')
        tmp.setAttribute('href', res.data)
        console.log(res.data)
        tmp.click()
      })
    },
    exportCheckedCombData () {
      const year = parseInt(this.monthChoose.substring(0, 4))
      const month = parseInt(this.monthChoose.substring(this.monthChoose.length - 2))
      const companies = []
      for (let i = 0; i < this.selectCombination.length; i++) {
        const item = this.combinationsInfo[this.selectCombination[i]].children
        item.forEach(row => {
          const idx = companies.indexOf(row.code)
          if (idx === -1) companies.push(row.code)
        })
      }
      exportCheckedRateData(year, month, companies).then(res => {
        if (res.code !== 0) {
          this.$message({
            message: '导出失败',
            type: 'danger',
            center: true
          })
          this.combBtn = false
          return
        }
        this.combBtn = false
        this.$message({
          message: '导出成功',
          type: 'success',
          center: true
        })
        const tmp = document.createElement('a')
        tmp.setAttribute('href', res.data)
        console.log(res.data)
        tmp.click()
      })
    },
    exportAllData () {
      // 导出全部数据
      this.allBtn = true
      const year = parseInt(this.monthChoose.substring(0, 4))
      const month = parseInt(this.monthChoose.substring(this.monthChoose.length - 2))
      this.modifyIndustry()
      exportRateData(year, month, this.industryModify).then(res => {
        if (res.code !== 0) {
          this.$message({
            message: '导出失败',
            type: 'danger',
            center: true
          })
          return
        }
        this.allBtn = false
        this.$message({
          message: '导出成功',
          type: 'success',
          center: true
        })
        const tmp = document.createElement('a')
        tmp.setAttribute('href', res.data)
        // console.log(res.data)
        tmp.click()
      })
    },
    selectAll () {
      this.selectIndustry = []
      if (this.isAllSelected) {
        this.industryOptions.map((item) => {
          this.selectIndustry.push(item.value)
        })
      } else {
        this.selectIndustry = []
      }
    },
    changeSelect (val) {
      this.isAllSelected = val.length === this.industryOptions.length
    },
    selectAllCombination () {
      this.selectCombination = []
      if (this.isCombinationAllSelected) {
        this.selectIndustry = []
        this.combinationOptions.map((item) => {
          this.selectCombination.push(parseInt(item.value) - 1)
        })
      } else {
        this.selectCombination = []
      }
    },
    changeCombinationSelect (val) {
      if (val.length !== 0) this.selectIndustry = []
      else {
        this.companyMode = true
        this.btnVisible(true)
        this.reload()
      }
      this.isCombinationAllSelected = val.length === this.combinationOptions.length
    },
    goToCombination () {
      this.$router.push('/combination')
    },
    handleCompanyClick (row, column, cell, event) {
      if (column.label === '公司名称' && row.code.length === 6) {
        // 跳转到对应公司
        this.$router.push({
          path: '/rank/company',
          query: {
            code: row.code,
            year: this.monthChoose.substring(0, 4),
            month: this.monthChoose.substring(this.monthChoose.length - 2)
          }
        })
      }
    },
    ensureFilter () {
      // 依据左侧行业筛选数据
      // 依据左侧组合筛选数据
      if (this.selectCombination.length === 0) {
        this.getAllData()
        this.btnVisible(true)
        this.companyMode = true
      } else {
        this.tableData = []
        this.companyMode = false
        this.totalNum = this.selectCombination.length
        this.btnVisible(false)
        this.selectCombination.forEach(comb => {
          this.tableData.push(this.combinationsInfo[comb])
        })
      }
    },
    btnVisible (btnState) {
      const allBtn = document.getElementById('all-btn')
      const checkBtn = document.getElementById('check-btn')
      const clearBtn = document.getElementById('clear-btn')
      const combBtn = document.getElementById('comb-btn')
      const combCheck = document.getElementById('check-comb-btn')
      if (btnState) {
        allBtn.style.display = 'inline-block'
        checkBtn.style.display = 'inline-block'
        clearBtn.style.display = 'inline-block'
        combBtn.style.display = 'none'
        combCheck.style.display = 'none'
      } else {
        allBtn.style.display = 'none'
        checkBtn.style.display = 'none'
        clearBtn.style.display = 'none'
        combBtn.style.display = 'inline-block'
        combCheck.style.display = 'inline-block'
      }
    },
    loadCombinationsInfo () {
      // 处理所有组合评级信息
      this.combinationsInfo = []
      for (let i = 0; i < this.combinationOptions.length; i++) {
        const comb = this.combinationOptions[i]
        const rowData = {
          code: '',
          industry: '自选组合',
          name: comb.label
        }
        const children = []
        for (let x = 0; x < comb.companies.length; x++) {
          const one = this.combCompaniesData[comb.companies[x]]
          if (!one) continue
          const oneData = {
            code: one.code,
            name: one.name,
            industry: this.industryNames[one.industry],
            fatherName: comb.label
          }
          for (let j = 0; j < this.scoreList.length; j++) {
            oneData[this.scoreList[j]] = one.ratings[this.scoreList[j]]
          }
          children.push(oneData)
        }
        for (let j = 0; j < this.scoreList.length; j++) {
          const tmp = children.map(item => {
            return item[this.scoreList[j]]
          }).join(',').split(',')
          rowData[this.scoreList[j]] = this.getAvg(tmp)
        }
        rowData.children = children
        this.combinationsInfo.push(rowData)
      }
      // console.log(this.combinationsInfo)
    },
    getAvg (scores) {
      let sum = 0
      let tmp = 0
      scores.forEach(score => {
        sum += parseFloat(score)
        if (parseFloat(score) > 0) tmp += 1
      })
      tmp = tmp === 0 ? 1 : tmp
      return sum / tmp
    },
    ensureSearch () {
      // 依据右上角公司名称或代码检索数据
      const year = parseInt(this.monthChoose.substring(0, 4))
      const month = parseInt(this.monthChoose.substring(this.monthChoose.length - 2))
      searchRateData(this.keyword, year, month).then(res => {
        if (res.code !== 0) {
          this.$message({
            message: '暂时没有数据噢',
            type: 'warning',
            center: true
          })
          return
        }
        this.tableData = []
        this.totalNum = res.data.totalNum
        const tmp = res.data
        const rowData = {
          industry: this.industryNames[tmp.industry],
          name: tmp.name,
          code: tmp.code
        }
        for (let i = 0; i < this.scoreList.length; i++) rowData[this.scoreList[i]] = tmp.ratings[this.scoreList[i]]
        this.tableData.push(rowData)
      })
    },
    chooseMonth () {
      // 返回对应月份的数据
      const year = parseInt(this.monthChoose.substring(0, 4))
      const month = parseInt(this.monthChoose.substring(this.monthChoose.length - 2))
      if (year > 2022 || (year === 2022 && month - 1 > new Date().getMonth())) {
        this.$message({
          message: '超出当前日期',
          type: 'warning',
          center: true
        })
        this.monthChoose = this.monthStore
        return
      }
      this.getAllData()
    },
    loadAllSelfComb () {
      // 获取该用户全部自选组合用以左侧筛选及组合内所有涉及到的公司
      getCombInfo(this.userId).then(res => {
        for (let i = 0; i < res.data.length; i++) {
          const tmp = res.data[i].portfolio.map(item => {
            return item.code
          }).join(',').split(',')
          this.combinationOptions.push({
            label: res.data[i].name,
            value: i + 1 + '',
            companies: tmp
          })
          tmp.forEach(row => {
            if (!this.combCompanies.includes(row)) this.combCompanies.push(row)
          })
        }
        this.loadCombData()
      })
    },
    loadCombData () {
      // 获取所有组合内公司评级信息
      const year = parseInt(this.monthChoose.substring(0, 4))
      const month = parseInt(this.monthChoose.substring(this.monthChoose.length - 2))
      getCompaniesRateData(year, month, this.combCompanies).then(res => {
        for (let i = 0; i < res.data.length; i++) {
          if (!res.data[i].ratings) this.combCompaniesData[res.data[i].code] = null
          else this.combCompaniesData[res.data[i].code] = res.data[i]
        }
        // console.log(this.combCompaniesData)
        this.loadCombinationsInfo()
      })
    },
    getAllData () {
      // console.log(this.selectIndustry)
      this.loading = true
      const year = parseInt(this.monthChoose.substring(0, 4))
      const month = parseInt(this.monthChoose.substring(this.monthChoose.length - 2))
      this.modifyIndustry()
      getRateData(year, month, this.currentPage, this.pageSize, this.industryModify, this.sortedBy, this.sortedOrder).then(res => {
        if (res.code !== 0) {
          this.$message({
            message: '暂无该月ESG评级数据',
            type: 'warning',
            center: true
          })
          this.loading = false
          this.monthChoose = this.monthStore
          return
        }
        this.tableData = []
        this.totalNum = res.data.totalNum
        for (let i = 0; i < res.data.list.length; i++) {
          const tmp = res.data.list[i]
          const rowData = {
            industry: this.industryNames[tmp.industry],
            name: tmp.name,
            code: tmp.code
          }
          for (let j = 0; j < this.scoreList.length; j++) {
            rowData[this.scoreList[j]] = tmp.ratings[this.scoreList[j]]
          }
          this.tableData.push(rowData)
        }
        this.loading = false
        // this.$message({
        //   message: '成功加载ESG评级数据',
        //   type: 'success',
        //   center: true
        // })
        this.monthStore = this.monthChoose
        this.topTableData.forEach(row => {
          for (let i = 0; i < this.tableData.length; i++) {
            if (row.code === this.tableData[i].code) {
              this.tableData.splice(i, 1)
              break
            }
          }
          this.tableData.unshift(row)
        })
        this.preChecked()
        this.topBtn = true
      })
    },
    modifyIndustry () {
      let tmp = []
      for (let i = 0; i < this.industryOptions.length; i++) {
        tmp.push(this.selectIndustry.includes(this.industryOptions[i].value))
      }
      if (this.selectIndustry.length === 0) {
        tmp = []
        for (let i = 0; i < this.industryOptions.length; i++) tmp.push(true)
      }
      this.industryModify = tmp
    },
    getUpdateDate () {
      if (this.$store.state.lastCheckDate.length > 0) {
        this.monthChoose = this.$store.state.lastCheckDate
        this.monthStore = this.monthChoose
        this.getAllData()
        return
      }
      getCurDate().then(res => {
        if (res.code !== 0) {
          this.$message({
            message: '获取更新时间失败',
            type: 'warning',
            center: true
          })
        } else {
          this.monthChoose = res.data.substring(0, res.data.lastIndexOf('-'))
          this.monthStore = this.monthChoose
          this.$store.commit('setLastCheckDate', { lastCheckDate: this.monthStore })
        }
        this.getAllData()
        this.loadAllSelfComb()
      })
    },
    refresh () {
      this.$store.commit('setLastCheckDate', { lastCheckDate: '' })
      this.reload()
    }
  },
  mounted () {
    this.getUpdateDate()
    // this.loadAllSelfComb()
    // this.getAllData()
  },
  created () {
    // 联动登录注册
    this.tableData = []
    this.topTableData = []
    this.loading = true
    this.latestMonth=this.$store.state.date.split('-')[1] || '5'
    this.latestYear=this.$store.state.date.split('-')[0] || '2022'
    this.userId = this.$store.state.userID || 1
    if (this.userId <= 0) this.userId = 1
    document.oncontextmenu = function (event) {
      event.preventDefault()
    }
  }
}
</script>

<style>
.el-header {
  background-color: #34495E;
  color: #333;
  text-align: center;
  margin-top: 0;
}

.title {
  background-color: #E9EEF3;
  margin: 0;
  font-size: 25px;
  line-height: 1rem;
  text-align: left;
  display: inline-block;
  cursor: pointer;
}

.el-main {
  background-color: #E9EEF3;
  color: #333;
  text-align: center;
  height: 100%;
}

.el-table .top-row {
  background: #c0c4cc;
}

.el-table .odd-row {
  background: #f9fafc;
}

.current-row > td {
  background: rgba(0, 158, 250, 0.219) !important;
}

.month-picker {
}

.other-btns {
  display: inline-flex;
  height: 2.5rem;
  align-items: center;
}

.selectAll-checkbox {
  text-align: right;
  width: 90%;
}

h1 {
  font-size: 2em;
  font-weight: normal;
}

h2 {
  font-size: 1.75rem;
  font-weight: normal;
}

h3 {
  font-size: 1.4rem;
  font-weight: normal;
  line-height: 1.8rem;
}

h4 {
  font-size: 1.1rem;
  font-weight: bold;
  line-height: 1.3rem;
}

::-webkit-scrollbar {
  width: 0 !important;
}

::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}
</style>
