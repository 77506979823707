import axios from 'axios'
import store from '../store'
import {COMPANY_MODULE} from '@/api/_prefix'
import router from '../router'

export const getRateData = (year, month, currentPage, pageSize, industry, sortedBy, order) => {
  return axios.post(`${COMPANY_MODULE}/getRatingBrowserData`,
    {
      year: year,
      month: month,
      currentPage: currentPage,
      pageSize: pageSize,
      industryChecked: industry,
      sortedBy: sortedBy,
      order: order
    }, {
      headers: {
        Authorization: store.state.token
      }
    }).then(res => {
    return res.data
  }).catch(function (error) {
    if (error.response) {
      if (error.response.data.code > 0 && error.response.data.code < 5) {
        router.push('/login')
      }
    }
  })
}

export const exportRateData = (year, month, industry) => {
  return axios.post(`${COMPANY_MODULE}/downloadAllData`,
    {
      year: year,
      month: month,
      industryChecked: industry
    }, {
      headers: {
        Authorization: store.state.token
      }
    }).then(res => {
    return res.data
  }).catch(function (error) {
    if (error.response) {
      if (error.response.data.code > 0 && error.response.data.code < 5) {
        router.push('/login')
      }
    }
  })
}

export const exportCheckedRateData = (year, month, companyChecked) => {
  return axios.post(`${COMPANY_MODULE}/getCheckedRatingBrowserData`, {
    year: year,
    month: month,
    companyChecked: companyChecked
  }, {
    headers: {
      Authorization: store.state.token
    }
  }).then(res => {
    return res.data
  }).catch(function (error) {
    if (error.response) {
      if (error.response.data.code > 0 && error.response.data.code < 5) {
        router.push('/login')
      }
    }
  })
}

export const searchRateData = (info, year, month) => {
  return axios.get(`${COMPANY_MODULE}/getCompanyInfo`,
    {
      params: {
        year: year,
        info: info,
        month: month
      },
      headers: {
        Authorization: store.state.token
      }
    }).then(res => {
    return res.data
  }).catch(function (error) {
    if (error.response) {
      if (error.response.data.code > 0 && error.response.data.code < 5) {
        router.push('/login')
      }
    }
  })
}

export const getCompaniesRateData = (year, month, companyChecked) => {
  return axios.post(`${COMPANY_MODULE}/getCompaniesInfo`,
    {
      year: year,
      month: month,
      companyChecked: companyChecked
    }, {
      headers: {
        Authorization: store.state.token
      }
    }).then(res => {
    return res.data
  }).catch(function (error) {
    if (error.response) {
      if (error.response.data.code > 0 && error.response.data.code < 5) {
        router.push('/login')
      }
    }
  })
}

export const getCurDate = () => {
  return axios.get(`${COMPANY_MODULE}/getESGUpdateTime`,
    {
      headers: {
        Authorization: store.state.token
      }
    }).then(res => {
    return res.data
  }).catch(function (error) {
    if (error.response) {
      if (error.response.data.code > 0 && error.response.data.code < 5) {
        router.push('/login')
      }
    }
  })
}
